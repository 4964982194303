import React from "react"
import { Form, Field } from 'react-final-form'
import styled from "styled-components"
import emailjs from 'emailjs-com'
import { Link } from "gatsby"

const Wrapper = styled.div`
  width: 420px;
  margin: 0 auto 0 auto;
  position: relative;
  
  @media (max-width: 992px) {
    padding: 0 50px 0 50px;
    margin: 50px 0 50px 0;
    width: 100%;  
  }
`;

const Title = styled.h1`
  font-size: 28px;
  font-family: "Playfair Display", serif;
  text-align: center;
  margin: 35px 0;
  
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 20px;
  }
  
  @media (max-width: 992px) {
    font-size: 20px;
    margin: 30px 0;
  }
`;

const StyledInput = styled.input`
  width: -webkit-fill-available;
  border: none;
  border-bottom: 1px solid #898989;
  font-size: 15px;
  color: black;
  margin-bottom: 12px;
  outline: none;
  padding: 13px;
  font-family: "Work Sans", sans-serif;
  
  @media (max-width: 1200px) {
    font-size: 14px;
  }
  
  &:focus, &:active {
    &::placeholder {
      color: black;
    }
    
    border-color: black;
  }
`

const StyledTextarea = styled.textarea`
  width: -webkit-fill-available;
  border: none;
  border-bottom: 1px solid #898989;
  font-size: 15px;
  color: black;
  outline: none;
  padding: 13px;
  font-family: "Work Sans", sans-serif;
  height: 150px;
  resize: vertical;
  
  @media (max-width: 1200px) {
    font-size: 14px;
    height: 150px;
  }
  
    &:focus, &:active {
    &::placeholder {
      color: black;
    }
    
    border-color: black;
  }
`;

const Button = styled.button`
  text-transform: uppercase;
  font-size: 20px;
  font-family: "Work Sans", sans-serif;
  padding: 16px 30px;
  background: #000;
  border: none;
  color: white;
  position: absolute;
  right: 0;
  outline: none;
  cursor: pointer;
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  @media (max-width: 1200px) {
    position: inherit;
    display: flex;
    justify-content: center;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: 20px;
  
  @media (max-width: 1200px) {
    margin-bottom: 48px;
  }
`

const Error = styled.span`
  color: #C40000;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  font-size: 15px;
  
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`

const StyledLabel = styled.label`
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  padding-left: 14px;
  
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`

const Info = styled.p`
  font-size: 15px;
  text-align: center;
  margin-bottom: 25px;
  font-family: "Work Sans", serif;
  
  @media (max-width: 1200px) {
    font-size: 12px;
  }
`

const ButtonWrapper = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    justify-content: flex-end;
  }
  
  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
  }
`

function onSubmit() {
  emailjs.sendForm('service_2h7mvki', 'template_fkxqqbi', document.getElementById('form'), 'user_NM3MuZ82zW1sftdlJjecZ')
    .then((result) => {
      window.location.reload()
    }, (error) => {
      console.log(error.text);
    });

  window.alert('Wiadomość została pomyślnie wysłana!');
}

const CustomForm = () => {

  return (
      <Wrapper>
      <Title>Formularz kontaktowy</Title>
        <Info>*Pola obowiązkowe</Info>
        <Info>Proszę nie podawać w treści wiadomości danych osobowych (nazwisk, adresów zamieszkania, etc)</Info>
      <Form
        onSubmit={onSubmit}
        validate={values => {
          const errors = {}
          if (!values.email) {
            errors.email = 'Wypełnij to pole!'
          }
          if (!values.message) {
            errors.message = 'Wypełnij to pole!'
          }
          if (!values.checkbox) {
            errors.checkbox = 'Zgoda jest wymagana!'
          }

          return errors
        }}
        render={({ handleSubmit, submitting, pristine, form }) => (
          <form onSubmit={handleSubmit} id="form">
            <Field name="email">
              {({ input, meta }) => (
                <InputWrapper>
                  <StyledInput {...input} type="email" placeholder="E-mail*"/>
                  {meta.error && meta.touched && <Error>{meta.error}</Error>}
                </InputWrapper>
              )}
            </Field>
            <Field name="phone">
              {({ input, meta }) => (
                <InputWrapper>
                  <StyledInput {...input} type="text" placeholder="Numer telefonu"/>
                  {meta.error && meta.touched && <Error>{meta.error}</Error>}
                </InputWrapper>
              )}
            </Field>
            <Field name="topic">
              {({ input, meta }) => (
                <InputWrapper>
                  <StyledInput {...input} type="text" placeholder="Temat"/>
                  {meta.error && meta.touched && <Error>{meta.error}</Error>}
                </InputWrapper>
              )}
            </Field>
            <Field name="message">
              {({ input, meta }) => (
                <InputWrapper>
                  <StyledTextarea {...input} type="text" placeholder="Wiadomość*"/>
                  {meta.error && meta.touched && <Error>{meta.error}</Error>}
                </InputWrapper>
              )}
            </Field>
            <Field name="checkbox">
              {({ input, meta }) => (
                <InputWrapper style={{ display: 'flex', alignItems: 'center'}}>
                  <input {...input} type="checkbox" id="checkbox" placeholder="Wiadomość*"/>
                  <StyledLabel htmlFor="checkbox"><Link to='/polityka-prywatnosci'>Polityka prywatności</Link>* <br/>
                    Wyrażam zgodę na przetwarzania moich danych osobowych zamieszczonych w formularzu
                    kontaktowym w celu udzielenia odpowiedzi na wiadomość oraz w celu nawiązania kontaktu
                    telefonicznego lub mailowego.
                  </StyledLabel><br/>

                  {meta.error && meta.touched && <Error>{meta.error}</Error>}
                </InputWrapper>
              )}
            </Field>
            <ButtonWrapper>
              <Button type="submit" disabled={submitting || pristine}>
                Wyślij
              </Button>
            </ButtonWrapper>
          </form>
        )}
      />
    </Wrapper>
    )
}

export default CustomForm;
