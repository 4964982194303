import React from "react"
import styled from "styled-components"
import Map, { location } from "../Map/Map"

const Wrapper = styled.div`
  background-color: #2D3137;
  width: 100%;
`

const AddressWrapper = styled.div`
  display: flex;
  height: 350px;
  width: 100%;
  justify-content: space-between;
  
  &:first-child {
    padding-top: 50px;
  }
`

const Address = styled.div`
  font-size: 30px;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  text-transform: uppercase;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  font-weight: 300;
  position: relative;
  
   @media (max-width: 992px) {
    width: inherit;
    font-size: 25px;
    line-height: 40px;
    padding: 0 20px;
  }
`

const MapWrapper = styled.div`
  width: 60%;
  background-color: #2B2D2F;
  margin-bottom: 12px;
  
  @media (max-width: 992px) {
    display: none;  
  }
`

const Hr = styled.hr`
  height: 1px;
  width: 100px;
  border: none;
  background-color: #fff;
  justify-content: center;
  position: absolute;
  bottom: 0;
  display: ${({hideOnMobile}) => hideOnMobile ? 'none' : 'flex'};
`

const ContactWithMaps = () => (
  <Wrapper>
    <AddressWrapper>
      <Address>
        oddział I: <br/>
        ul. romana dmowskiego 3 lok. 11 <br/>
        50-203 wrocław
        <Hr />
      </Address>
      <MapWrapper>
        
      </MapWrapper>
    </AddressWrapper>
    <AddressWrapper>
      <Address>
        siedzia: <br/>
        rynek im. jana pawła II nr 12 lok. 2 <br/>
        50-100 kłobuck
        <Hr />
      </Address>
      <MapWrapper />
    </AddressWrapper>
    <AddressWrapper>
      <Address>
        oddział II: <br/>
        ul. czecha 19a lok. 32 <br/>
        42-200 częstochowa
        <Hr hideOnMobile />
      </Address>
      <MapWrapper />
    </AddressWrapper>
  </Wrapper>
)

export default ContactWithMaps;