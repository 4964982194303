import React from "react"
import styled from "styled-components"
import Map, { location } from "../Map/Map"
import CustomForm from "../CustomForm/CustomForm"

const Container = styled.div`
  display: flex;
  
  @media (min-width: 992px) {
    height: auto !important;
  }
  
  @media (max-width: 992px) {
    flex-direction: column-reverse;
  }
`

const InfoWrapper = styled.div`
  background-color: #EAE1D6;
  width: 40%;
  
  @media (max-width: 992px) {
    width: 100%;
  }
`

const FormWrapper = styled.div`
  width: 60%;
  
  @media (max-width: 992px) {
    margin: auto;
    width: 100%;
  }
`

const AddressWrapper = styled.div`
  display: flex;
  height: 280px;
  
  @media (min-width: 992px) and (max-width: 1200px) {
    height: 250px;
  }
  
   @media (max-width: 992px) {
    height: 150px;
    
    &:last-of-type {
      display: none;
    }
  }
`

const Address = styled.div`
  font-size: 19px;
  line-height: 45px;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  text-transform: uppercase;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  position: relative;
  margin: auto;
  
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 14px;
    line-height: 30px;
  }
  
   @media (max-width: 992px) {
    width: inherit;
    font-size: 14px;
    line-height: 28px;
    padding: 0 20px;
  }
`

const MapWrapper = styled.div`
  width: 60%;
  background-color: #2B2D2F;
  margin-bottom: 12px;
  
  @media (max-width: 992px) {
    display: none;  
  }
`

const Hr = styled.hr`
  height: 1px;
  width: 100px;
  border: none;
  background-color: #000000;
  justify-content: center;
  position: absolute;
  top: 196px;
  display: ${({hideOnMobile}) => hideOnMobile ? 'none' : 'flex'};
  
  @media (min-width: 992px) and (max-width: 1200px) {
    top: 156px;
  }
  
  @media (max-width: 992px) {
    top: 107px;
    
    display: ${({hideOnMobile}) => hideOnMobile ? 'none' : 'flex'};
  }
`

const Title = styled.p`
  font-size: 30px;
  font-family: "Playfair Display", serif;
  color: black;
  font-weight: bold;
  
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 19px;
  }
`

const ContactText = styled.div`
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  margin: auto;
  
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 14px;
  }
`

const ContactWithForm = () => (
  <Container>
    <InfoWrapper>
      <AddressWrapper>
        <Address>
          kancelaria wrocław: <br/>
          ul. romana dmowskiego 3 lok. 11 <br/>
          50-203 wrocław
          <Hr />
        </Address>
      </AddressWrapper>
      <AddressWrapper>
        <Address>
          kancelaria kłobuck: <br/>
          rynek im. jana pawła II 12 lok. 2 <br/>
          42-100 kłobuck
          <Hr hideOnMobile/>
        </Address>
      </AddressWrapper>
      <AddressWrapper>
        <ContactText>
          <Title>Kontakt</Title>
          kancelaria@radcabm.pl <br/>
          tel. 508 343 810
        </ContactText>
      </AddressWrapper>
    </InfoWrapper>
    <FormWrapper>
      <CustomForm />
    </FormWrapper>
  </Container>

)

export default ContactWithForm;