import React from "react"
import styled from "styled-components"
import Map from "../Map/Map"
import { Marker } from "@react-google-maps/api";

const Wrapper = styled.div`
  background: #2d3236;
  padding: 60px 160px;
  
  @media (max-width: 992px) {
    padding: 15px;
  }
`

const City = styled.p`
  font-size: 35px;
  margin: 0 auto 35px auto;
  padding-bottom: 8px;
  text-align: center;
  border-bottom: 1px solid white;
  color: white;
  font-family: "Work Sans", serif;
  width: fit-content;
  
  @media (max-width: 992px) {
    font-size: 18px;
  }
  
  &:last-of-type {
    margin-top: 20px;
  }
`

const Maps = () => (
  <Wrapper>
    <City>Wrocław</City>
      <Map center={{
        lat: 51.117198,
        lng: 17.022333,
      }}>
        <Marker position={{
          lat: 51.117198,
          lng: 17.022333,
        }}/>
      </Map>
    <City>Kłobuck</City>
      <Map center={{
        lat: 50.905161867567976,
        lng: 18.93620340232281
      }}>
        <Marker position={{
          lat: 50.905161867567976,
          lng: 18.93620340232281
        }}/>
      </Map>
  </Wrapper>
);

export default Maps;