import React from "react"
import Header from "../components/Header/Header"
import GlobalStyles from "../GlobalStyles"
import ContactWithMaps from "../components/ContactWithMaps/ContactWithMaps"
import CustomForm from "../components/CustomForm/CustomForm"
import ContactWithForm from "../components/ContactWithForm/ContactWithForm"
import Footer from "../components/Footer/Footer"
import Maps from "../components/Maps/Maps"
import Helmet from "react-helmet"

const contact = () => (
  <>
  <GlobalStyles />
  <Header />
    <Helmet>
      <meta name="viewport"
            content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" />
      <meta http-equiv="X-UA-Compatible" content="ie=edge" />
      <meta charSet="utf-8" />
      <title>Kancelaria Merda</title>
      <link rel="canonical" href="https://kancelaria-merda.pl/" />
    </Helmet>
  <ContactWithForm />
  <Maps />

  <Footer />
  </>
)

export default contact;